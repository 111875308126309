<template>
  <loadOverlay v-if="isdashLoading" />
  <div v-else class="row">
    <div class="col-12" style="margin-bottom: 1%">
      <div class="row">

        <div class="col text-left d-flex  align-items-center ">
          <button class="btn  btn-info rounded-pill  btn-sm ntn-md SN-btn" style="cursor: copy;"
            @click="copyToClipboard">
            <span class="d-inline-block mr-2">{{
              0 > this.$store.getters.sn ? $t('SubNavBar.Overview') : "SN :"
            }}</span>
            <strong>{{
              this.$store.getters.sn == -1 ? "" : this.$store.getters.sn
            }}</strong>
          </button>
        </div>

        <div class="col d-flex justify-content-center  text-center ">
          <img class="img-fluid logo_sax_login" style="max-width: 100%; height: auto;" src="img/saxlogo.png"
            alt="Logo" />
        </div>

        <div class="col text-right d-flex align-items-center justify-content-end phases">
          <div v-if="Object.keys(sns).length > 1">
            <div class="btn-group btn-group-toggle " data-toggle="buttons">
              <div v-for="(sn, index) in sns" :key="sn.sn">
                <Serialnumber v-if="sn.sn === - 1" class="btn px-4 py-1" :sn="sn.sn" :label="`${sn.sn}`"
                  :is-active="sn.isActive" @reset-options="resetSelectedOptions" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="test"> </div> -->


    <div class="col-lg col-md-6" style="height:7rem;" v-for="(card, index) in updatedStatsCards" :key="index">
      <stats-card :title="card.title" :sub-title="card.subTitle" :type="card.type" :icon="card.icon"></stats-card>
    </div>



    <!-- Big Chart -->
    <div class="col-12" v-if="this.$store.getters.sn != -1">
      <div class="row">
        <div class="col-lg-8 col-md col-sm col" style="margin-bottom: 30px;">

          <card type="chart" class="d-flex h-100 flex-column">
            <Chart />

          </card>
        </div>
        <div class="col-lg-4 col-md col-sm col status" style="margin-bottom: 30px">
          <div class="card h-100">
            <div class="card-header">
              <div class="col-12 p-lg-4" style=" padding-top: 0 !important;">
                <div>
                  <SvgComponent ref="svgComponent" />{{ refresh }}
                </div>
                <div class="row">
                  <div class="col-4 col-md-4 d-flex justify-content-center align-items-center">
                    <div v-if="!isTotalSelected">
                      <div v-if="typeof isOnline !== 'undefined' && isOnline">
                        <p style="font-size:calc(1rem + 1vw); ;color: #42B883 !important; "
                          class=" font-weight-bold mb-0">
                          {{ "Online" }}
                        </p>
                      </div>
                      <div v-else>
                        <p style="font-size:calc(1rem + 1vw);color: #F4AB2A !important;"
                          class="font-weight-bold card-title">
                          {{ "Offline" }}
                        </p>

                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-md-4 d-flex justify-content-center align-items-center" style="height: 50px;">

                  </div>

                  <div class="col-4 col-md-4 d-flex justify-content-center align-items-center card-text">
                    <div style="font-size:calc(1rem + 0.5vw);">
                      <div v-if="batteryStatus === 4">
                        <strong class="text-uppercase " style="color: #285891;">standby</strong>
                      </div>
                      <div v-if="batteryStatus === 3">
                        <strong class="text-uppercase  " style="color: #285891;">{{ $t('status.connected') }}</strong>
                      </div>
                      <div v-if="batteryStatus === 2">
                        <strong class="text-uppercase " style="color: #285891;">{{ $t('status.on') }}</strong>
                      </div>
                      <div v-if="batteryStatus === 1">
                        <strong class="text-uppercase " style="color: #285891;">{{ $t('status.off') }}</strong>
                      </div>
                      <div v-if="!batteryStatus">
                        <strong class="text-uppercase "><br></strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" style="height: 100;">

              <div class="card" style="box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); margin-bottom: 0px">
                <div class="card-body d-flex flex-column px-2 px-lg-4">
                  <strong class=" d-flex justify-content-center pb-3" style="font-size: 1em; color: #285891;">{{
                    $t('oneItem.Messages') }}</strong>
                  <div>
                    <!-- Card as a clickable button -->
                    <div @click="openFloatingWindow" class="message-card"
                      :class="{ 'text-dark': !darkMode, 'text-white': darkMode, }">
                      <ul v-if="lastMessages.length" style="list-style: none; padding: 0; margin: 0;">
                        <li v-for="(letzteMeldung, index) in lastMessages.slice(0, 4)" :key="index"
                          :class="{ 'text-dark': !darkMode, 'text-white': darkMode }"
                          style="display: inline-block; margin-right: 10px; border:transparent !important;">
                          <small style="font-size: 1em;">
                            {{ letzteMeldung }}
                          </small>
                        </li>
                        <!-- If more than 4 messages, show an indicator for additional messages -->
                        <li v-if="lastMessages.length > 4" style="display: inline-block; margin-left: 10px;">
                          <small style="border:transparent !important;"
                            :class="{ 'text-dark': !darkMode, 'text-white': darkMode }">
                            +{{ lastMessages.length - 4 }}</small>
                        </li>
                      </ul>
                      <ul v-if="!lastMessages.length">
                        <li :class="{ 'text-dark': !darkMode, 'text-white': darkMode }"
                          style="border:transparent !important;">
                          <small style="font-size: 1em; border:transparent !important;"
                            :class="{ 'text-dark': !darkMode, 'text-white': darkMode }">
                            {{ $t('oneItem.NoMessages') }}
                          </small>
                        </li>
                      </ul>
                    </div>

                    <!-- Floating window (modal) to show all messages -->
                    <div v-if="isFloatingWindowVisible && lastMessages.length > 4" class="floating-window"
                      :class="{ 'text-dark': !darkMode, 'text-white': darkMode }" v-click-outside="closeFloatingWindow">
                      <div class="floating-window-content" :class="{ 'text-dark': !darkMode, 'text-white': darkMode }">
                        <ul style="list-style: none; padding: 0; max-height: fit-content; overflow-y: auto;">
                          <li v-for="(letzteMeldung, index) in lastMessages" :key="'modal-' + index"
                            :class="{ 'text-dark': !darkMode, 'text-white': darkMode }"
                            style="margin: 5px 0; border:transparent !important;">
                            <small style="font-size: 1em; border:transparent !important;"
                              :class="{ 'text-dark': !darkMode, 'text-white': darkMode }">
                              {{ letzteMeldung }}
                            </small>
                          </li>
                        </ul>
                        <div style="display: flex; justify-content: center; margin-top: 10px;">
                          <button @click="closeFloatingWindow" style="border-radius: 4px;"
                            :class="{ 'text-dark': !darkMode, 'text-white': darkMode }">
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>

        </div>
      </div>

    </div>





    <!-- Small charts -->
    <div class="col-lg-4" v-if="this.$store.getters.sn != -1">
      <card class="card-chart" no-footer-line>
        <template slot="header">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-category">{{ $t('smallchart.GridEnergy') }} </h5>
            <div>
              <b-form-select :class="{
                'rounded': true,
                'm-0': true,
                'p-0': true,
                'text-black': !darkMode,
                'text-white': darkMode
              }" v-model="timeSelected" :options="timeSeries" @change="handleChange" />
            </div>
            <DatePickerComponent
              :key="timeSelected === 'interval' ? `${chart_date.start}_${chart_date.end}` : chart_date"
              :style="{ visibility: timeSelected === 'total' ? 'hidden' : 'visible' }" :initialDate="chart_date"
              :timeSelected="timeSelected" :lang="lang" @date-selected="updateDate" />
          </div>
          <h3 class="card-title">
            <i style="font-size:1.2rem;" class="mdi mdi-transmission-tower mdi-36px text-primary"></i>
            ({{ average_m2 }} kWh) {{ $t('Total') }}
          </h3>
        </template>
        <div class="chart-area">
          <bar-chart :chart-data="get_data_m2.chartData" :extra-options="get_data_m2.extraOptions"
            :gradient-colors="get_data_m2.gradientColors" :gradient-stops="get_data_m2.gradientStops" :height="230">
          </bar-chart>
        </div>
      </card>
    </div>


    <div class="col-lg-4" v-if="this.$store.getters.sn != -1">
      <card class="card-chart">
        <template slot="header">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-category">{{ $t('smallchart.PV_Energy') }}</h5>
            <div>
              <b-form-select :class="{
                'rounded': true,
                'm-0': true,
                'p-0': true,
                'text-black': !darkMode,
                'text-white': darkMode
              }" v-model="timeSelected" :options="timeSeries" @change="handleChange" />
            </div>
            <DatePickerComponent
              :key="timeSelected === 'interval' ? `${chart_date.start}_${chart_date.end}` : chart_date"
              :style="{ visibility: timeSelected === 'total' ? 'hidden' : 'visible' }" :initialDate="chart_date"
              :timeSelected="timeSelected" :lang="lang" @date-selected="updateDate" />
          </div>
          <h3 class="card-title">
            <i style="font-size:1.2rem;" class="mdi mdi-solar-power-variant-outline mdi-36px text-primary"></i>
            ({{ average_m4 }} kWh) {{ $t('Total') }}
          </h3>
        </template>
        <div class="chart-area">
          <bar-chart :chart-data="get_data_m4.chartData" :extra-options="get_data_m4.extraOptions"
            :gradient-colors="get_data_m4.gradientColors" :gradient-stops="get_data_m4.gradientStops" :height="230">
          </bar-chart>
        </div>
      </card>
    </div>


    <div class="col-lg-4" v-if="this.$store.getters.sn != -1">
      <card class="card-chart">
        <template slot="header">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-category">{{ this.$t('smallchart.BatteryEnergy') }}</h5>
            <div>
              <b-form-select :class="{
                'rounded': true,
                'm-0': true,
                'p-0': true,
                'text-black': !darkMode,
                'text-white': darkMode
              }" v-model="timeSelected" :options="timeSeries" @change="handleChange" />
            </div>
            <DatePickerComponent
              :key="timeSelected === 'interval' ? `${chart_date.start}_${chart_date.end}` : chart_date"
              :style="{ visibility: timeSelected === 'total' ? 'hidden' : 'visible' }" :initialDate="chart_date"
              :timeSelected="timeSelected" :lang="lang" @date-selected="updateDate" />
          </div>
          <h3 class="card-title">
            <i style="font-size:1.2rem;" class="mdi mdi-car-battery mdi-36px text-primary"></i>
            ({{ average_m5 }} kWh) {{ $t('Total') }}
          </h3>
        </template>
        <div class="chart-area">
          <bar-chart :chart-data="get_data_m5.chartData" :extra-options="get_data_m5.extraOptions"
            :gradient-colors="get_data_m5.gradientColors" :gradient-stops="get_data_m5.gradientStops" :height="230">
          </bar-chart>
        </div>
      </card>
    </div>



    <div class="col-12">
      <card class=" lastM" style="padding-bottom:0; margin-bottom:0">
        <template slot="header" v-if="this.$store.getters.sn != -1">
          <h4 style="font-size: 2em;" class=" text-center">{{ $t('oneItem.Message') }}</h4>
        </template>
        <div class="card-body">
          <div v-if="this.$store.getters.sn != -1">
            <div class="table-responsive" style="height: 200px; overflow-y: auto;">
              <table class="table " style="width: 100%">
                <thead>
                  <th style="width: 60%;">{{ $t('oneItem.NA-Schutz') }}</th>
                  <th style="width: 40%;">{{ $t('oneItem.Date') }}</th>
                </thead>
                <tbody>
                  <tr v-for="(lastMessage, i) in lastMessagess" :key="i">
                    <td style="width: 40%;">
                      <div style="width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                        {{ isValidDate(lastMessage.time) ? convertMeldung(lastMessage.message) : '' }}
                      </div>
                    </td>
                    <td style="width: 60%;">
                      <div style="width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                        {{ isValidDate(lastMessage.time) ? convertDate(lastMessage.time) : '' }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else :class="{ 'text-dark': !darkMode, 'text-white': darkMode }">
            <vue-good-table :columns="columns" :rows="filteredSns"
              :search-options="{ enabled: true, placeholder: $t('oneItem.search') }" :sort-options="{ enabled: true }">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'sn_display'">
                  <Serialnumber class="btn px-4 py-1 " :Cls="'elect-butt'" :sn="props.row.sn"
                    :label="`${props.row.sn}`" :is-active="props.row.isActive" @reset-options="resetSelectedOptions" />
                </span>
                <span v-else-if="props.column.field === 'status' || props.column.field === 'online'">
                  <p v-if="props.column.field === 'online'"
                    :style="{ color: isOnlineForSn(props.row.sn) ? '#42B883' : '#F4AB2A', margin: 0 }">
                    {{ isOnlineForSn(props.row.sn) ? "Online" : "Offline" }}</p>

                  <p v-if="props.column.field === 'status'" style="margin: 0 0 0 10px; color: #5088cb;">{{
                    batteryStatus_table(props.row.sn) }}</p>
                </span>
                <span v-else-if="props.column.field === 'messages'">
                  <div @click="openFloatingWindow(props.row.messages)" class="message-card"
                    :class="{ 'text-dark': !darkMode, 'text-white': darkMode }">
                    <ul v-if="props.row.messages.length" style="list-style: none; padding: 0; margin: 0;">
                      <li v-for="(message, index) in props.row.messages.slice(0, 4)" :key="index"
                        :class="{ 'text-dark': !darkMode, 'text-white': darkMode }"
                        style="display: inline-block; margin-right: 10px; border:transparent !important;">
                        <small style="font-size: 1em; color: #a7a7a7;">
                          {{ message }}
                        </small>
                      </li>
                      <!-- If more than 4 messages, show an indicator for additional messages -->
                      <li v-if="props.row.messages.length > 4" style="display: inline-block; margin-left: 10px;">
                        <small style="border:transparent !important; color: #a7a7a7 !important;"
                          :class="{ 'text-dark': !darkMode, 'text-white': darkMode }">
                          +{{ props.row.messages.length - 4 }}
                        </small>
                      </li>
                    </ul>
                    <ul v-if="!props.row.messages.length">
                      <li :class="{ 'text-dark': !darkMode, 'text-white': darkMode }"
                        style="border:transparent !important; color: #a7a7a7 !important;">
                        <small style="font-size: 1em; border:transparent !important; color: #a7a7a7 !important;"
                          :class="{ 'text-dark': !darkMode, 'text-white': darkMode }">
                          {{ $t('oneItem.NoMessages') }}
                        </small>
                      </li>
                    </ul>
                  </div>

                  <!-- Floating window (modal) to show all messages -->
                  <transition name="fade">
                    <div v-if="isFloatingWindowVisible && props.row.messages.length > 4" class="floating-window"
                      :class="{ 'text-dark': !darkMode, 'text-white': darkMode }" v-click-outside="closeFloatingWindow">
                      <div class="floating-window-content" :class="{ 'text-dark': !darkMode, 'text-white': darkMode }">
                        <ul style="list-style: none; padding: 0; max-height: fit-content; overflow-y: auto;">
                          <li v-for="(message, index) in props.row.messages" :key="'modal-' + index"
                            :class="{ 'text-dark': !darkMode, 'text-white': darkMode }"
                            style="margin: 5px 0; border:transparent !important;">
                            <small style="font-size: 1em; border:transparent !important; color: #a7a7a7 !important;"
                              :class="{ 'text-dark': !darkMode, 'text-white': darkMode }">
                              {{ message }}
                            </small>
                          </li>
                        </ul>
                        <div
                          style="display: flex; justify-content: center; margin-top: 10px; color: #a7a7a7 !important;">
                          <button @click="closeFloatingWindow" style="border-radius: 5px; border: 1px solid #606266;"
                            :class="{ 'text-dark': !darkMode, 'text-white': darkMode }">
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </transition>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </card>
      <div class="row mt-5">
        <div class="col col-sm-12 d-flex justify-content-center ">

          <div class="card-style card">
            <h1 class="text-center" :class="{ 'text-white': darkMode, 'text-dark': !darkMode }">
              {{ $t('settings.addSN') }}
            </h1>
            <div class="card-container">
              <p style="color: #a7a7a7 !important" :class="{ 'text-white': darkMode, 'text-dark': !darkMode }">{{ $t('settings.addSNText') }}</p>
              <input :class="{ 'text-white': darkMode, 'text-dark': !darkMode }" type="text"
                :placeholder="$t('settings.code') +' 1'" v-model="values.serialNumber1">
                <input :class="{ 'text-white': darkMode, 'text-dark': !darkMode }" type="text"
                :placeholder="$t('settings.code') +' 2'" v-model="values.serialNumber2">
                <input :class="{ 'text-white': darkMode, 'text-dark': !darkMode }" type="text"
                :placeholder="$t('settings.code') +' 3'" v-model="values.serialNumber3">
            </div>
            <div class="d-flex justify-content-center  align-items-center">
              <button class="btnn btn-info" @click="submit">{{ $t('settings.save') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>





import Chart from "@/components/Linechart/Chart.vue";
import MyChart from '@/components/Linechart/chart/MyChart.vue';
import Battary from "@/components/Linechart/Battary.vue";

import BarChart from 'src/components/Charts/BarChart';
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';


import StatsCard from "src/components/Cards/StatsCard";
import { store } from "@/store";
import Serialnumber from "@/components/Linechart/Serialnumber.vue";
import { saxMaps } from "@/modules/maps";

import SvgComponent from 'src/assets/images/dot.svg';

import loadOverlay from "@/components/loadOverlay";

import { mapState } from 'vuex';
import { getSeriesUnit, getSeriesLabel, getSeries, getTimeSeries, } from "@/components/Linechart/chart/constants";
import DatePickerComponent from './DatePickerComponent.vue';
import debounce from 'lodash.debounce'
import axios from 'axios';
export default {
  name: "ElectricianDashboard",
  components: {
    Chart,
    Serialnumber,
    StatsCard,
    MyChart,
    BarChart,
    Battary,
    SvgComponent,
    loadOverlay,
    DatePickerComponent

  },
  data() {
    const timeSmallChart = this.$store.getters.chartSelector.time_smallChart;
    const [timeSelected, chartDate] = timeSmallChart.split("_");
    return {

      // showbatt_house: false,
      // showpv_batt: false,
      // showgrid_house: false,
      // showgrid_batt: false,
      // showpv_house: false,
      // showpv_grid: false,
      // showSin: false,



      showgrid_N: false,
      showgrid_P: false,
      showcons: false,
      showpv_P: false,
      showbatt_P: false,
      showbatt_N: false,

      showblue: false,
      showalert: false,
      showstandby: false,

      hasNoMessage: true,
      selectedOption: 1,
      selectedOptionM: 1,
      selectedOptionMM: 1,
      selectedOption_soc: 1,

      useLineAndBoarderColor: '#42b883',
      BackgroundAreaColor: [
        'rgba(76, 211, 150, 0.1)',
        'rgba(53, 183, 125, 0)',
        'rgba(119,52,169,0)'
      ],

      sUseLineAndBoarderColor: '#285891',
      sBackgroundAreaColor: [
        'rgba(90, 123, 255, 0.1)',
        'rgba(53, 183, 125, 0)',
        'rgba(119, 52, 169, 0)'
      ],



      // darkMode: this.$store.getters.getDarkMode,
      selectedSN: null,
      dataInterval: undefined,
      isLoading: false,
      isInitializing: true,
      date: new Date(),
      // sns: this.$store.getters.sns ? this.$store.getters.sns : {},
      phases: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
      activeIndex: 0,
      timeNow: undefined,
      datePicker: '',
      // timeSelected: 'week',
      today: new Date(),
      // chart_date: this.$dayjs().format('YYYY-MM-DD'),
      timeSelected: timeSelected || "week",
      chart_date: timeSelected === "interval"
        ? { start: "", end: "" }
        : chartDate || this.$dayjs(new Date()).format("YYYY-MM-DD"),
      isFloatingWindowVisible: false,
      closeingFloatingWindow: false,
      columns: [
        { label: "SN", field: "sn_display", sortable: true, type: "number" },
        { label: this.$t("oneItem.phase"), field: "phase", sortable: false },
        { label: this.$t("oneItem.status"), field: "status", sortable: true },
        { label: "Online", field: "online", sortable: true },
        { label: this.$t("oneItem.Messages"), field: "messages", sortable: false, width: '30%' },
        { label: this.$t("oneItem.SOC"), field: "soc", sortable: true, type: "number", width: '10%' },
        { label: this.$t("oneItem.user"), field: "user", sortable: false, sortable: true, width: '10%' },
      ],
      values: {
        serialNumber1: '',
        serialNumber2: '',
        serialNumber3: '',
      },
    };
  },
  created() {
    this.isLoading = true;
    this.$store.dispatch('checkSnUpdate');
    this.sendParamsAndGetData();
    this.$store.dispatch("get_current_data");
    this.$store.dispatch('get_sns');
    this.dataInterval = setInterval(() => {
      try {
        this.$store.dispatch("get_current_data");

        try {
          if (this.$store.getters.getAccessStatus === 401) {
            this.$store.dispatch("getNewAccessToken");
          }
        }
        catch (error) {
          this.$router.push('/login');
        }
      } catch (error) {

      }
    }, 3000);
    localStorage.setItem("updateStarted", JSON.stringify({ sn: null, updateStarted: "" }));
  },
  computed: {
    ...mapState(['darkMode']),
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    isdashLoading() {
      this.isLoading = this.$store.getters.dashLoading;
      // console.log("refresh", this.$store.getters.dashLoading)
      return this.$store.getters.dashLoading;
    },
    refresh() {
      // this.showbatt_house = false;
      // this.showpv_batt = false;
      // this.showgrid_house = false;
      // this.showgrid_batt = false;
      // this.showpv_house = false;
      // this.showpv_grid = false;
      // this.showSin = false;

      this.showgrid_N = false,
        this.showgrid_P = false,
        this.showcons = false,
        this.showpv_P = false,
        this.showbatt_P = false,
        this.showbatt_N = false,

        this.showblue = false;
      this.showalert = false;
      this.showstandby = false;

      console.log("m2: ", this.m2, " m5: ", this.m5, " m4: ", this.m4)

      // if (this.m2 === 0 || this.m5 === 0) {
      //   if (this.m4 === 0 || this.m4 < 0) this.showpv_house = false;
      //   else this.showpv_house = true;

      //   if (this.m2 === 0) this.showgrid_house = false;
      //   else if (this.m2 > 0) this.showgrid_house = true;
      //   else this.showpv_grid = true;

      //   if (this.m5 === 0) this.showbatt_house = false;
      //   else if (this.m5 > 0) this.showbatt_house = true;
      //   else this.showpv_batt = true;

      // }
      // if (this.m2 > 0 && this.m5 < 0) {
      //   this.showgrid_batt = true; this.showgrid_house = true;

      // }
      // if (this.m2 > 0 && this.m5 > 0) {
      //   this.showbatt_house = true; this.showgrid_house = true;

      // }
      // if (this.m2 < 0 && this.m5 < 0) {
      //   this.showpv_grid = true; this.showpv_batt = true; this.showpv_house = true;

      // }
      // if (this.m2 < 0 && this.m5 > 0) {
      //   this.showpv_grid = true; this.showbatt_house = true; this.showpv_house = true;

      // }

      // if (this.m4 > 0 || (this.m2 < 0 && this.m5 <= 0) || (this.m2 <= 0 && this.m5 < 0)) this.showpv_P = true;
      this.showpv_P = this.showgrid_P = this.showbatt_P = this.showgrid_N = this.showbatt_N = this.showcons = this.showblue = false;
      if (!this.isOnline && this.$store.getters.sn != -1) {
      }
      else {
        if (this.m4 > 0) this.showpv_P = true;
        if (this.m4 != 0 || this.m2 != 0 || this.m5 != 0) this.showcons = true;

        if (this.m2 === 0) {

        }

        if (this.m5 === 0) {

        }

        if ((this.m2 > 0 && this.m5 < 0) || (this.m2 < 0 && this.m5 > 0)) {
          // console.log("(this.m2 > 0 && this.m5 < 0) || (this.m2 < 0 && this.m5 > 0)")
          if (Math.abs(this.m2) > Math.abs(this.m5)) {
            // console.log("Math.abs(this.m2):", Math.abs(this.m2),">", Math.abs(this.m5),"Math.abs(this.m5)")
            if (this.m2 > 0) {
              this.showgrid_P = true;
              if (this.m5 >= 30) this.showbatt_P = true;
            }
            else if (this.m2 < 0) {
              // console.log("this.m2) neg")
              this.showgrid_N = true;
              if (this.m5 <= -30) this.showbatt_N = true;
              this.showpv_P = true;
            }
          }
          else if (Math.abs(this.m2) < Math.abs(this.m5)) {
            // console.log("Math.abs(this.m2):", Math.abs(this.m2),"<", Math.abs(this.m5),"Math.abs(this.m5)")
            if (this.m5 > 0) {
              this.showgrid_P = true;
              if (this.m5 >= 30) this.showbatt_P = true;
            }
            else if (this.m5 < 0) {
              this.showgrid_N = true;
              this.showpv_P = true;
              if (this.m5 <= -30) this.showbatt_N = true;
            }
          }
          else if (Math.abs(this.m2) === Math.abs(this.m5)) {
            // console.log("Math.abs(this.m2):", Math.abs(this.m2),"=", Math.abs(this.m5),"Math.abs(this.m5)")
            if (this.m2 > 0) {
              // console.log("this.m2) pos")
              this.showgrid_P = true;
              if (this.m5 >= 30) this.showbatt_P = true;
            }
            else if (this.m2 < 0) {
              this.showgrid_N = true;
              this.showpv_P = true;
              if (this.m5 <= -30) this.showbatt_N = true;
              else if (this.m5 >= 30) this.showbatt_P = true;
            }
          }
        }
        else if (this.m2 > 0 && this.m5 > 0) {
          this.showgrid_P = true;
          if (this.m5 >= 30) this.showbatt_P = true;
        }
        else if (this.m2 < 0 && this.m5 < 0) {
          // console.log("this.m2 < 0 && this.m5 < 0")
          this.showgrid_N = true;
          this.showpv_P = true;
          if (this.m5 <= -30) this.showbatt_N = true;
        }
        else if (this.m5 > 30) { this.showbatt_P = true; }
        else if (this.m5 < -30) { this.showbatt_N = true; this.showpv_P = true; }
        else if (this.m2 > 0) { this.showgrid_P = true; }
        else if (this.m2 < 0) { this.showgrid_N = true; this.showpv_P = true; }


        if (!this.lastMessages.length) this.showblue = true;
        if (this.batteryStatus === 4) {
          this.showstandby = true;
        }
        if (this.lastMessages.length) {
          this.showalert = true;
        }

      }
      return this.updateSvgVisibility()


    },
    updatedStatsCards() {
      if (this.$store.getters.sn != -1) {
        this.isLoading = true;
        const soc = Math.floor(this.m6 / 10) * 10;

        let batteryIcon;
        if (this.m6 > 0) batteryIcon = soc === 0 ? 'mdi mdi-battery-charging-10' : `mdi mdi-battery-charging-${soc}`;
        else batteryIcon = soc === 0 ? 'mdi mdi-battery-charging-outline' : `mdi mdi-battery-charging-${soc}`;

        let grid_power;
        if (this.m2 < 0) grid_power = "mdi mdi-transmission-tower-import";
        else if (this.m2 == 0) grid_power = "mdi mdi-transmission-tower";
        else grid_power = "mdi mdi-transmission-tower-export";

        const updatedStatsCards = [
          {
            title: `${this.m6.toString()}%`,
            subTitle: this.$t('StateCards.SOC'),
            type: "info",
            icon: batteryIcon,
          },
          {
            title: `${this.m5.toString()} W`,
            subTitle: this.$t('StateCards.Batterypower'),
            type: "success",
            icon: "mdi mdi-car-battery",
          },
          {
            title: `${this.m4.toString()} W`,
            subTitle: this.$t('StateCards.PV_power'),
            type: "info",
            icon: "mdi mdi-solar-power-variant-outline",
          },
          // {
          //   title: `${this.m1.toString()} V`,
          //   subTitle: this.$t('StateCards.Gridvoltage'),
          //   type: "success",
          //   icon: "mdi mdi-lightning-bolt",
          // },
          {
            title: `${this.m2.toString()} W`,
            subTitle: this.$t('StateCards.GridPower'),
            type: "success",
            icon: grid_power,
          },



        ];
        this.isLoading = false;
        return updatedStatsCards;
      }
    },
    m6AsInteger() {

      return parseInt(this.m6, 10);
    },
    lastMessagess() {

      store.getters.lastMessages[store.getters.sn]?.last_messages?.last_messages?.map(
        (message) => {
          if (message.time !== "2000-0-0 0:0:0.0") this.hasNoMessage = false;
        }
      );

      return store.getters.lastMessages[store.getters.sn]?.last_messages?.last_messages || [{ time: '2000-0-0 0:0:0.0' }];


    },
    lang() {
      return this.$i18n.locale;
    },
    timeZone() {
      return store.getters.timeZone;
    },









    isOnline: () => {
      try {


        if (
          !store.getters.lastOnlineFrom[store.getters.sn]
        ) {
          return false;
        } else {
          return (
            store.getters.lastOnlineFrom[store.getters.sn].last_online_from <
            600 || store.getters.updateStarted[store.getters.sn].update_started
          );
        }
      } catch (error) {
        console.error("Navbar(Isonline) Error isOnline has no value will 0 be returned");
        return 0;
      }

      //return 1;
    },
    isTotalSelected: () => {
      return store.getters.sn == -1;
    },
    lastMessages() {
      if (store.getters.sn == -1) return [];
      else {
        try {
          store.commit(
            "setMessage2",
            { message2: store.getters.allMessage2[store.getters.sn].message2 }
          );

          return store.getters.message2;
        } catch (error) {
          console.error(
            "General(lastmessages) lastmessages has error and will be returned as []"
          );
          return [];
        }
      }
    },

    areThereWarnings() {
      let hasAlarm = 0;

      this.lastMessages.map((el) => {
        if (el != i18n.tc("warten60")) hasAlarm += 1;
      });

      return hasAlarm;
    },
    batteryStatus() {
      try {
        if (store.getters.sn == -1) return null;
        else return store.getters.batteryStatus[store.getters.sn].batteryStatus || null;
      } catch (error) {
        return 1;
      }
    },
    get_m2_label: () => {
      return store.getters.getChatLabel_m2;
    },
    get_m4_label: () => {
      return store.getters.getChatLabel_m4;
    },
    get_m5_label() {
      return store.getters.getChatLabel_m5;
    },
    get_m6_label() {
      return store.getters.getChatLabel_m6;
    },
    // get_data_m2: () => {
    //   return store.getters.getChatData_m2;
    // },
    get_data_m2() {
      let chart_config = {
        chartData: {
          // labels: store.getters.getChatLabel_m2.map(date => {
          //   return date.split('-')[2];
          // }),
          labels: this.timeSelected === 'year'
            ? Array.from({ length: 12 }, (_, i) => i + 1)
            : this.timeSelected === 'total'
              ? store.getters.getChatLabel_m2
              : store.getters.getChatLabel_m2
                .filter(date => typeof date === 'string' && date !== null)
                .map(date => date.split('-')[2]),
          datasets: [
            {
              label: 'Data',
              fill: true,
              backgroundColor: 'rgba(76, 211, 150, 0.1)',
              borderColor: '#42b883',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: store.getters.getChatData_m2,
            }
          ]
        },
        extraOptions: {
          ...chartConfigs.barChartOptionsGradient,
          scales: {
            ...chartConfigs.barChartOptionsGradient.scales,
            yAxes: chartConfigs.barChartOptionsGradient.scales.yAxes.map(yAxis => ({
              ...yAxis,
              scaleLabel: {
                ...yAxis.scaleLabel,
                labelString: this.$t('smallchart.GridEnergy') + " [Wh]",
              }
            }))
          }
        },
        gradientColors: [
          'rgba(76, 211, 150, 0.1)',
          'rgba(53, 183, 125, 0)',
          'rgba(119,52,169,0)'
        ],
        gradientStops: [1, 0]
      }
      return chart_config;
    },
    average_m2: () => {
      const chatData_m2 = store.getters.getChatData_m2;

      if (!chatData_m2 || chatData_m2.length === 0) {
        return 0;
      }

      const sum = chatData_m2.reduce((acc, curr) => acc + curr, 0);
      // return Math.round(sum / chatData_m2.length);
      return ((Math.round(sum)) / 1000).toFixed(2);
    },
    average_m4: () => {
      const chatData_m4 = store.getters.getChatData_m4;

      if (!chatData_m4 || chatData_m4.length === 0) {
        return 0;
      }

      const sum = chatData_m4.reduce((acc, curr) => acc + curr, 0);
      return ((Math.round(sum)) / 1000).toFixed(2);
    },
    average_m5: () => {
      const chatData_m5 = store.getters.getChatData_m5;

      if (!chatData_m5 || chatData_m5.length === 0) {
        return 0;
      }

      const sum = chatData_m5.reduce((acc, curr) => acc + curr, 0);
      // return Math.round(sum / chatData_m5.length);
      return ((Math.round(sum)) / 1000).toFixed(2);
    },
    average_m6: () => {
      const chatData_m6 = store.getters.getChatData_m6;

      if (!chatData_m6 || chatData_m6.length === 0) {
        return 0;
      }

      const sum = chatData_m6.reduce((acc, curr) => acc + curr, 0);
      return Math.round(sum / chatData_m6.length);
    },
    // get_data_m4: () => {
    //   return store.getters.getChatData_m4;
    // },
    get_data_m4() {
      let chart_config = {
        chartData: {
          labels: this.timeSelected === 'year'
            ? Array.from({ length: 12 }, (_, i) => i + 1)
            : this.timeSelected === 'total'
              ? store.getters.getChatLabel_m4
              : store.getters.getChatLabel_m4
                .filter(date => typeof date === 'string' && date !== null)
                .map(date => date.split('-')[2]),
          datasets: [
            {
              label: 'Data',
              fill: true,
              backgroundColor: 'rgba(218, 197, 85, 0.1)',
              borderColor: '#dac555',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: store.getters.getChatData_m4,
            }
          ]
        },
        extraOptions: {
          ...chartConfigs.barChartOptionsGradient,
          scales: {
            ...chartConfigs.barChartOptionsGradient.scales,
            yAxes: chartConfigs.barChartOptionsGradient.scales.yAxes.map(yAxis => ({
              ...yAxis,
              scaleLabel: {
                ...yAxis.scaleLabel,
                labelString: this.$t('smallchart.PV_Energy') + " [Wh]",
              }
            }))
          }
        },
        gradientColors: [
          'rgba(76, 211, 150, 0.1)',
          'rgba(53, 183, 125, 0)',
          'rgba(119,52,169,0)'
        ],
        gradientStops: [1, 0]
      }
      return chart_config;
    },
    // get_data_m5() {
    //   return store.getters.getChatData_m5;
    // },
    get_data_m5() {
      let chart_config = {
        chartData: {
          labels: this.timeSelected === 'year'
            ? Array.from({ length: 12 }, (_, i) => i + 1)
            : this.timeSelected === 'total'
              ? store.getters.getChatLabel_m5
              : store.getters.getChatLabel_m5
                .filter(date => typeof date === 'string' && date !== null)
                .map(date => date.split('-')[2]),
          datasets: [
            {
              label: 'Data',
              fill: true,
              backgroundColor: 'rgba(90, 123, 255, 0.1)',
              borderColor: '#285891',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: store.getters.getChatData_m5,
            }
          ]
        },
        extraOptions: {
          ...chartConfigs.barChartOptionsGradient,
          scales: {
            ...chartConfigs.barChartOptionsGradient.scales,
            yAxes: chartConfigs.barChartOptionsGradient.scales.yAxes.map(yAxis => ({
              ...yAxis,
              scaleLabel: {
                ...yAxis.scaleLabel,
                labelString: this.$t('smallchart.BatteryEnergy') + " [Wh]",
              }
            }))
          }
        },
        gradientColors: [
          'rgba(76, 211, 150, 0.1)',
          'rgba(53, 183, 125, 0)',
          'rgba(119,52,169,0)'
        ],
        gradientStops: [1, 0]
      }
      return chart_config;
    },
    // get_data_m6() {
    //   return store.getters.getChatData_m6;
    // },
    // get_data_m6() {
    //   let chart_config = {
    //     chartData: {
    //       labels: store.getters.getChatLabel_m6.map(date => {
    //         return date.split('-')[2];
    //       }),
    //       datasets: [
    //         {
    //           label: 'Data',
    //           fill: true,
    //           backgroundColor: 'rgba(76, 211, 150, 0.1)',
    //           borderColor: '#42b883',
    //           borderWidth: 2,
    //           borderDash: [],
    //           borderDashOffset: 0.0,
    //           data: store.getters.getChatData_m6,
    //         }
    //       ]
    //     },
    //     extraOptions: {
    //       ...chartConfigs.barChartOptionsGradient,
    //       scales: {
    //         ...chartConfigs.barChartOptionsGradient.scales,
    //         yAxes: chartConfigs.barChartOptionsGradient.scales.yAxes.map(yAxis => ({
    //           ...yAxis,
    //           scaleLabel: {
    //             ...yAxis.scaleLabel,
    //             labelString: this.$t('oneItem.SOC') + " [%]",
    //           }
    //         }))
    //       }
    //     },
    //     gradientColors: [
    //       'rgba(76, 211, 150, 0.1)',
    //       'rgba(53, 183, 125, 0)',
    //       'rgba(119,52,169,0)'
    //     ],
    //     gradientStops: [1, 0]
    //   }
    //   return chart_config;
    // },

    m1: () => {
      try {
        if (store.getters.sn == -1) {
          let temp = 0;

          Object.keys(store.getters.grid_voltage).map((sn) => {
            temp = temp + store.getters.grid_voltage[sn].grid_voltage;
          });

          temp = temp / (store.getters.sns.length - 1); //Average value
          return Number(temp).toFixed(0); //.toFixed(2); showing 2 decimal places
        } else {
          return store.getters.grid_voltage[store.getters.sn].grid_voltage;
        }
      } catch (error) {
        return "-";
      }
    },
    m2: () => {
      try {
        if (store.getters.sn == -1) {
          //return store.getters.grid_power[store.getters.sns[0].sn].grid_power;
          let temp = 0;

          Object.keys(store.getters.grid_power).map((sn) => {
            temp = temp + store.getters.grid_power[sn].grid_power;
          });

          temp = temp / (store.getters.sns.length - 1);
          return Number(temp).toFixed(0); //.toFixed(2); showing 2 decimal places
        } else return store.getters.grid_power[store.getters.sn].grid_power;
      } catch (error) {
        return "-";
      }
    },
    m3: () => {
      try {
        if (store.getters.sn == -1) {
          return store.getters.discharge_energy[store.getters.sns[0].sn]
            .discharge_energy;
        } else
          return store.getters.discharge_energy[store.getters.sn]
            .discharge_energy;
      } catch (error) {
        return "_";
      }
    },
    m4: () => {
      try {
        if (store.getters.sn == -1) {
          return store.getters.PV_power[store.getters.sns[0].sn].PV_power;
        } else return store.getters.PV_power[store.getters.sn].PV_power;
      } catch (error) {
        return "-";
      }
    },
    m5: () => {
      try {
        if (store.getters.sn == -1) {
          let temp = 0;

          Object.keys(store.getters.battery_power).map((sn) => {
            temp = temp + store.getters.battery_power[sn].battery_power;
          });

          //temp = temp / (store.getters.sns.length - 1); Average value
          return Number(temp).toFixed(0); //.toFixed(2); showing 2 decimal places
        } else
          return store.getters.battery_power[store.getters.sn].battery_power;
      } catch (error) {
        return "-";
      }
    },

    m6: () => {
      try {
        if (store.getters.sn == -1) {
          let temp = 0;

          Object.keys(store.getters.SOC).map((sn) => {
            temp = temp + store.getters.SOC[sn].SOC;
          });

          temp = temp / (store.getters.sns.length - 1);
          return Number(temp).toFixed(0); //.toFixed(2); showing 2 decimal places
        } else return store.getters.SOC[store.getters.sn].SOC;
      } catch (error) {
        return "-";
      }
    },
    sns() {
      return this.$store.getters.sns;
    },
    timeSeries() {
      return getTimeSeries('energy');
    },
    test() {
      console.log("###test", this.$store.getters.sns.find(sn => sn.sn === 4).user);
    },
    filteredSns() {
      return this.sns
        .filter(item => item.sn !== -1)
        .map(item => ({
          ...item,
          sn_display: item.sn,
          phase: `Phase ${this.phases[item.phase - 1]}`,
          status: this.batteryStatus_table(item.sn),
          online: this.isOnlineForSn(item.sn) ? "Online" : "Offline",
          messages: this.getLastMessagesForSn(item.sn),
          soc: `${this.$store.getters.SOC[item.sn].SOC}%`,
          user: this.$store.getters.sns.find(sn => sn.sn === item.sn).user == " " ? "-" : this.$store.getters.sns.find(sn => sn.sn === item.sn).user,
        }));
    },
  },
  methods: {
    updateSvgVisibility() {
      const svgElements = document.getElementsByTagName('svg');
      if (svgElements.length > 0) {
        const svgElement = svgElements[1]; // Assuming it's the first SVG
        // const batt_house = svgElement.getElementById('batt_house');
        // const pv_batt = svgElement.getElementById('pv_batt');
        // const grid_house = svgElement.getElementById('grid_house');
        // const grid_batt = svgElement.getElementById('grid_batt');
        // const pv_house = svgElement.getElementById('pv_house');
        // const pv_grid = svgElement.getElementById('pv_grid');
        // const Sin = svgElement.getElementById('Sin');


        const grid_N = svgElement.getElementById('grid_N');
        const grid_P = svgElement.getElementById('grid_P');
        const cons = svgElement.getElementById('cons');
        const pv_P = svgElement.getElementById('pv_P');
        const batt_P = svgElement.getElementById('batt_P');
        const batt_N = svgElement.getElementById('batt_N');

        const blue = svgElement.getElementById('blue');
        const alert = svgElement.getElementById('alert');
        const standby = svgElement.getElementById('standby');

        // batt_house.style.visibility = this.showbatt_house ? '' : 'hidden';
        // pv_batt.style.visibility = this.showpv_batt ? '' : 'hidden';
        // grid_house.style.visibility = this.showgrid_house ? '' : 'hidden';
        // grid_batt.style.visibility = this.showgrid_batt ? '' : 'hidden';
        // pv_house.style.visibility = this.showpv_house ? '' : 'hidden';
        // pv_grid.style.visibility = this.showpv_grid ? '' : 'hidden';
        // Sin.style.visibility = this.showSin ? '' : 'hidden';


        grid_N.style.visibility = this.showgrid_N ? '' : 'hidden';
        grid_P.style.visibility = this.showgrid_P ? '' : 'hidden';
        cons.style.visibility = this.showcons ? '' : 'hidden';
        pv_P.style.visibility = this.showpv_P ? '' : 'hidden';
        batt_P.style.visibility = this.showbatt_P ? '' : 'hidden';
        batt_N.style.visibility = this.showbatt_N ? '' : 'hidden';

        blue.style.visibility = this.showblue ? '' : 'hidden';
        alert.style.visibility = this.showalert ? '' : 'hidden';
        standby.style.visibility = this.showstandby ? '' : 'hidden';

      }

    },


    convertMeldung(msg) {
      return saxMaps.getMeldung(msg);
    },
    convertOnOff(msg) {
      return saxMaps.getOnOff(msg);
    },
    convertDate(date) {
      if (date == "") return "-";
      // return this.$i18n.d(this.$dayjs(date).utcOffset(this.$dayjs.utcOffset).$d, 'year_time')
      return this.$i18n.d(this.$dayjs(date), "year_time");
    },

    isValidDate(dateString) {
      try {
        if (!dateString) {
          return false;
        }
        const date = new Date(dateString);
        return !isNaN(date.getTime());
      } catch (error) {
        console.error("General(isValidDate) isValidDate has error and will return false", error);
        return false;
      }

    },





    resetSelectedOptions() {
      this.selectedOption = 1;
      this.selectedOptionM = 1;
      this.selectedOptionMM = 1;
      this.selectedOption_soc = 1;
    },
    handleSelection(options) {

      let time;

      if (this.timeSelected === "interval") {
        let start = this.chart_date?.start || this.$dayjs(new Date()).subtract(1, "week").format("YYYY-MM-DD");
        let end = this.chart_date?.end || this.$dayjs(new Date()).format("YYYY-MM-DD");
        const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/; // Matches DD.MM.YYYY format
        if (dateRegex.test(start)) {
          start = this.$dayjs(start, "DD.MM.YYYY").format("YYYY-MM-DD");
        }
        if (dateRegex.test(end)) {
          end = this.$dayjs(end, "DD.MM.YYYY").format("YYYY-MM-DD");
        }
        time = `${this.timeSelected}_${start}~${end}`;
      } else {
        time = `${this.timeSelected}_${this.chart_date}`;
      }

      this.$store.commit("setChartSelector", {
        seriesSelected: this.$store.getters.chartSelector.seriesSelected,
        timeSelected: this.$store.getters.chartSelector.timeSelected,
        method: this.$store.getters.chartSelector.method,
        date: this.$store.getters.chartSelector.date,
        time_smallChart: time,
      });

      if (this.timeSelected === "interval") {
        const [intervalLabel, startEnd] = time.split("_");
        const [start, end] = startEnd.split("~");
        this.chart_date = { start, end };
      } else {
        this.timeSelected = time.split("_")[0];
        this.chart_date = time.split("_")[1];
      }
      const params = {
        sn: store.getters.sn,
        m2: options.m2,
        m4: options.m4,
        m5: options.m5,
        days: time,
      };
      if (store.getters.sn != -1) {
        this.$store.dispatch("get_energy_chart_data", params);
      }
    },

    async sendParamsAndGetData() {
      // this.isLoading = true;
      try {
        await store.dispatch("get_current_data");
        await store.dispatch(
          "get_selected_data",
          this.$store.getters.chartSelector
        );
        // console.log("get_selected_data", this.$store.getters.chartSelector);
      } catch (error) {
        console.log("error", error);
      }

      // this.isLoading = false;
    },
    async copyToClipboard() {
      try {
        const valueToCopy = this.$store.getters.sn.toString();
        await navigator.clipboard.writeText(valueToCopy);
        // alert('Copied to clipboard');
        this.$notify({
          type: 'info',
          message: `Copied`,
          horizontalAlign: 'center',
          timeout: 600,
          showClose: false,
          style: { width: 'auto' }
        });
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
    handleChange() {
      this.updateCharts();
    },
    updateDate: debounce(function (selectedDate) {
      if (this.chart_date !== selectedDate) {
        this.chart_date = selectedDate;
        this.updateCharts();
      }
    }, 300),
    updateCharts() {
      this.handleSelection({ m2: true, m4: true, m5: true });
    },
    // draw_m2() {
    //   this.handleSelection({ m2: true, m4: false, m5: false })
    // },
    // updateDate_m2(selectedDate) {
    //   this.chart_date_m2 = selectedDate;
    //   this.draw_m2();
    // },
    // draw_m4() {
    //   this.handleSelection({ m2: false, m4: true, m5: false })
    // },
    // updateDate_m4(selectedDate) {
    //   this.chart_date_m4 = selectedDate;
    //   this.draw_m4();
    // },
    // draw_m5() {
    //   this.handleSelection({ m2: false, m4: false, m5: true })
    // },
    updateDate_m5(selectedDate) {
      this.chart_date_m5 = selectedDate;
      this.draw_m5();
    },
    openFloatingWindow(messages) {
      if (this.isFloatingWindowVisible === false) {
        if (messages && messages.length > 4) {
          this.selectedMessages = messages;
          this.isFloatingWindowVisible = true;
          this.closeingFloatingWindow = true;
        }
      } else {
        this.isFloatingWindowVisible = false;
        this.closeingFloatingWindow = false;
      }
    },
    closeFloatingWindow() {
      if (this.isFloatingWindowVisible === true && !this.closeingFloatingWindow) {
        this.isFloatingWindowVisible = false;
      } else {
        this.closeingFloatingWindow = false;
      }
    },
    checkTimeAndDrawChart() {
      const date = new Date();
      const minutes = date.getMinutes();
      const hours = date.getHours();
      if ((minutes - 1) % 5 === 0) {
        this.handleChange();
      } else if (minutes === 0) {
        this.today = new Date();
      }
    },
    isOnlineForSn(sn) {
      try {
        if (!store.getters.lastOnlineFrom[sn]) {
          return false;
        } else {
          return (
            store.getters.lastOnlineFrom[sn].last_online_from < 600
          );
        }
      } catch (error) {
        console.error("Navbar(isOnlineForSn) Error: isOnlineForSn has no value; 0 will be returned", error);
        return 0;
      }
    },
    batteryStatus_table(sn) {
      const batteryStatus = store.getters.batteryStatus[sn].batteryStatus;
      if (batteryStatus === 4) return this.$t('status.standby');
      if (batteryStatus === 3) return this.$t('status.connected');
      if (batteryStatus === 2) return this.$t('status.on');
      if (batteryStatus === 1) return this.$t('status.off');
      return this.$t('status.unknown');
    },
    getLastMessagesForSn(sn) {
      try {
        store.commit(
          "setMessage2", {
          message2: store.getters.allMessage2[sn].message2,
          sn: sn,
        });
        return store.getters.message2;
      } catch (error) {
        console.error(
          "General(lastmessages) lastmessages has error and will be returned as []"
        );
        return [];
      }
    },
    async submit() {
      // Show a popup to ask for first and last name
      const { value: formValues } = await Swal.fire({
        title: this.$t('settings.userInfo'),
        html: `
      <p style="color:black">${this.$t('settings.pleaseFillName')}</p>
      <input id="swal-first-name" class="swal2-input" placeholder="${this.$t('settings.firstName')}">
      <input id="swal-last-name" class="swal2-input" placeholder="${this.$t('settings.lastName')}">
    `,
        focusConfirm: true,
        showCancelButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: "#285891",
        cancelButtonText: this.$t('settings.cancelButtonText'),
        preConfirm: () => {
          const firstName = document.getElementById('swal-first-name').value.trim();
          const lastName = document.getElementById('swal-last-name').value.trim();

          if (!firstName || !lastName) {
            Swal.showValidationMessage(this.$t('settings.requiredFields'));
            return false;
          }

          return { firstName, lastName };
        }
      });

      if (!formValues) return; // Stop if the user cancels

      const { firstName, lastName } = formValues;
      const numericArray = [];

      for (const key of Object.keys(this.values)) {
        const value = this.values[key];
        if (value && value !== 0) {
          numericArray.push(value);
        }
      }

      if (numericArray.length > 0) {
        try {
          const token = localStorage.getItem("access_token");
          const requestBody = {
            sn: numericArray,
            first_name: firstName,
            last_name: lastName
          };

          const endpoint = '/api/auth/add_sn_electrician/';
          const url = process.env.VUE_APP_API_ENDPOINT + endpoint;
          const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          };

          const response = await axios.post(url, requestBody, { headers });

          const failed = this.$t('settings.failedby');
          const success = this.$t('settings.successby');
          const message = `${success} ${response.data["success by"].length}: [${response.data["success by"]}]<br>${failed} ${response.data["failed by"].length}: [${response.data["failed by"]}]<br> ${this.$t('settings.relog')}`;

          Swal.fire({
            title: '',
            html: message,
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: "#285891",
          });

        } catch (error) {
          Swal.fire({
            title: ' ',
            text: this.$t('settings.errorMessage'),
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: "#285891",
          });
        }
      } else {
        Swal.fire({
          title: ' ',
          text: this.$t('settings.message'),
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: "#285891",
        });
      }

    },
  },
  mounted() {
    this.updateSvgVisibility();
    // alert('YOU ARE AN ELECTRICIAN');
    // if (!localStorage.getItem('alertShown')) {
    //   alert('We\'re updating the SAX-Power Kunden portal on March 25, 2024. The site will be unavailable during the update. Thanks for your patience!');
    //   localStorage.setItem('alertShown', 'true');
    // }
    // alert('We\'re updating the SAX-Power Kunden portal on March 25, 2024. The site will be unavailable during the update. Thanks for your patience!');
    this.checkTimeAndDrawChart();
    this.intervalId = setInterval(this.checkTimeAndDrawChart, 60000);
  },
  watch: {
    timeSelected: {
      immediate: true,
      handler(newTime, oldTime) {
        if (oldTime === "interval") {
          this.chart_date = this.$dayjs(new Date()).format("YYYY-MM-DD");
        }
      }
    },
    // Watch for changes in active SN
    // '$store.state.sn': {
    // immediate: true,
    // // handler(newSn, oldSn) {
    //   this.handleSelection({ m2: true, m4: false, m5: false });
    //   this.handleSelectionM({ m2: false, m4: true, m5: false });
    //   this.handleSelectionMM({ m2: false, m4: false, m5: true });
    //   this.handleSelection_SOC();
    // }
    // }
  },
  beforeDestroy() {
    clearInterval(this.dataInterval);
    clearInterval(this.intervalId);


  },
};
</script>
<style scoped>
.custom-select {
  height: inherit;
  width: 4rem;
  text-align: center !important;
  cursor: pointer;
  background: #27293D;
}

.card-category {
  margin-bottom: 0px !important;
}

.message-card {
  cursor: pointer;
  padding: 10px;
  background-color: #f0f0f0;
  /* Light background for button effect */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  display: inline-block;
  width: 100%;
  /* Full width if needed */
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.message-card:hover {
  background-color: #e0e0e0;
  /* Darker on hover for button effect */
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;
}

.message-card:active {
  background-color: #e0e0e0;
  /* Darker on hover for button effect */
  box-shadow: inset rgba(0, 0, 0, 0.3) 0px 0px 10px;
}

/* Style for the floating window/modal */
.floating-window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 300px;
  /* Adjust as needed */
  max-height: fit-content;
  overflow: hidden;
  border-radius: 8px;
}

.floating-window-content {
  padding: 15px;
}

.text-white {
  background-color: #27293D !important;
  border: 1px solid #222a42;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  margin: 10px auto;
  display: block;
  border-radius: 5px;
  border: 1px solid #606266;
  width: 100%;
  background: none;
}

input[type="text"].text-dark {
  border: 1.5px solid #dcdfe6 !important;
}

input[type="text"]:focus {
  outline: 1px solid #606266;
}

.text-center.text-dark {
  color: #606266 !important;
}

.text-center.text-white {
  color: #a7a7a7 !important;
}

.card-style {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 20px;
}

.card-container {
  width: 100%;
}

.btnn {
  width: 10rem;
  color: white;
  border: 0;
  padding: 7px;
  border-radius: 5px;
}
</style>
<style>
select.rounded {
  /* Resetting styles */


  background-color: #27293D;

  color: white;
  /* Add any additional default styles if needed */
}

.last-style {
  background: #27293D;

}

.last-style option {
  color: rgb(231, 12, 12);

}

.vgt-right-align {
  text-align: left;
}

table.vgt-table {
  border-collapse: inherit;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
}

.text-white table.vgt-table {
  background: #1e1e2e;
  background-color: #1e1e2e;
  border: 1px solid #606266;
  border-radius: 5px;
}

.vgt-global-search {
  border: 1px solid #dcdfe6;
  border-radius: 5px;
}

.text-white .vgt-global-search input::placeholder {
  color: #dcdfe6;
}

.text-white .vgt-global-search {
  background: #1e1e2e;
  border: 1px solid #606266;
  border-radius: 5px;
}

.vgt-input,
.vgt-select {
  border: 1px solid #dcdfe6;
  border-radius: 5px;
}

.text-white .vgt-input,
.vgt-select {
  background-color: #27293D;
  border: 1px solid #606266;
  border-radius: 5px;
  color: #a7a7a7;
}

.vgt-table thead th {
  border: 1px solid #dcdfe6;
  border-radius: 5px;
}

.text-white .vgt-table thead th {
  background: #1e1e2e;
  background-color: #1e1e2e;
  border: 1px solid #606266;
  border-radius: 5px;
  color: #a7a7a7;
}

table.vgt-table td {
  border: 1px solid #dcdfe6;
  border-radius: 5px;
}

.text-white table.vgt-table td {
  background: #27293D;
  background-color: #27293D;
  border: 1px solid #606266;
  border-radius: 5px;
  color: #a7a7a7;
}

/* For PC (Desktop) */
@media screen and (min-width: 1024px) {}

/* For iPad (Tablet) */
@media screen and (min-width: 378px) and (max-width: 5555px) {
  .SN-btn {
    width: 10rem !important;
    height: 2rem !important;
  }

  .logo_sax_login {
    min-width: 10rem !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .butt {
    font-size: 18px;
    width: 12px;
    height: 19px;
    margin: 3px;
    padding-left: 7px;
    margin-right: 20px;
  }

  .overview {
    font-size: auto;
    height: 15px;
    margin: 5px;
    margin-top: 3px;
    margin-bottom: 7px;
  }

  .phase_text {
    font-size: 0px;
    width: 0px;
    height: 0px;
  }
}

/* For Normal Phones */
@media screen and (max-width: 767px) {
  .lastM {
    margin-top: 30px;
  }

  .phases {
    padding-top: 5%;


  }

  .phases_container {
    display: flex;
    justify-content: center;
  }

  .transparent-card {
    background-color: rgba(219, 18, 18, 0.5);
    /* Adjust the alpha value (0-1) for desired transparency */
  }

  .centered-div {
    width: 50%;
    /* Adjust the width as needed */
    margin: 0 auto;
    /* This centers the div horizontally */
  }

  .selector-bg {
    background-color: #27293D !important;
  }

  .selector-t {
    color: #fff !important;
  }

}
</style>