<template>
  <button v-if="isActive" @click="getSnData()" class="btn-success serial-number-btn">
    <div v-if="sn == -1">
      {{ $t('SubNavBar.Overview') }}
    </div>
    <div :class="Cls" v-else v-html="label"></div>
    <span v-if="showPlusIcon" class="plus-icon">+</span>
  </button>
  <button v-else @click="getSnData()" class="btn-info serial-number-btn">
    <div class="overview" v-if="sn == -1">
      {{ $t('SubNavBar.Overview') }}
    </div>
    <div :class="Cls" v-else v-html="label"></div>
    <span v-if="showPlusIcon" class="plus-icon">+</span>
  </button>
</template>

<script>
import { store } from "@/store";

export default {
  name: "Serialnumber",
  data() {
    return {
      chart_date: this.$dayjs().format('YYYY-MM-DD'),
    };
  },
  props: {
    sn: {
      required: true,
      type: undefined,
    },
    isActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
    Cls: {
      type: String,
      required: false,
      default: "butt"
    },
    showPlusIcon: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.isActive && this.sn == -1) {
      this.getSnData();
    }
  },
  computed: {
    isActiveComputed() {
      return this.sn === this.$store.state.sn;
    }
  },
  methods: {

    resetSelectedOptions() {
      this.$emit('reset-options');
    },
    setActive() {
      let sns = this.$store.getters.sns;
      sns = sns.map((sn) => {
        if (sn.sn == this.sn) {
          sn.isActive = true;
        } else sn.isActive = false;
        return sn;
      });
      this.$store.commit("updateSns", sns);
      this.$store.commit("setSn", this.sn);
    },
    getSnData() {
      this.setActive();
      let time = this.$store.getters.chartSelector.time_smallChart !== "" ?
        this.$store.getters.chartSelector.time_smallChart : `week_${this.chart_date}`;
      console.log("time", time);
      if (this.$store.getters.sn != -1 && !this.isElectrician) {
        this.$emit('reset-options');
        this.$store.dispatch("get_energy_chart_data", {
          sn: store.getters.sn,
          m2: true,
          m4: true,
          m5: true,
          days: time,

        });
        // let parames = {
        //   sn: store.getters.sn,

        //   days: time,
        // };
        // this.$store.dispatch("get_soc_chart_data", parames);


      } else if (this.electrician) {
        this.$emit('reset-options');
      } else {
        // console.log("overview");
        this.$store.dispatch("get_energy_chart_data_overview", {
          m2: true,
          m4: true,
          m5: true,
          days: time,
        });
        // this.$store.dispatch("get_Soc_chart_data_overview", {

        //   days: 7,
        // });
        this.$emit('reset-options');

      }

    },
  },
};
</script>
<style scoped>
.serial-number-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px; /* Space for the plus icon */
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.plus-icon {
  font-size: 1rem;
  color: #007bff;
  margin-left: 8px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  transform: translate(-50%, -50%);
}

.plus-icon:hover {
  color: #0056b3;
  transform: translate(-50%, -50%) scale(1.2);
}
</style>
<style></style>
