<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard :prevButtonText="updatedPrevButtonText" :nextButtonText="updatedNextButtonText"
          :finishButtonText="updatedFinishButtonText" :endButtonText="updatedEndButtonText">
          <template slot="header">
            <h3 class="card-title">{{ $t("update.header") }}</h3>
            <h3 class="description" v-html="$t('update.description')"></h3>
            <div class="container">
              <div :class="{ darkMode: darkMode, lightMode: !darkMode }">
                <el-select :class="{ 'select-primary': !darkMode, 'select-secondary': darkMode }"
                  @change="handleSelectChange" v-model="selectedSn" :disabled="isSelectDisabled" filterable
                  :placeholder="getAllSn.length === 0 ? $t('update.NoUpdate') : $t('update.selectormessage')"
                  style="width: 17rem;">
                  <el-option v-for="sn in getAllSn" :key="sn" :value="sn">
                    {{ sn }}
                  </el-option>
                </el-select>
              </div>
            </div>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="mdi mdi-numeric-1-circle-outline mdi-36px"></i>
            </template>
            <first-step ref="step1" @on-validated="onStepValidated"></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="mdi mdi-numeric-2-circle-outline mdi-36px"></i>
            </template>
            <second-step ref="step2" @on-validated="onStepValidated"></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="mdi mdi-numeric-3-circle-outline mdi-36px"></i>
            </template>
            <third-step ref="step3"></third-step>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import FirstStep from "./Wizard/FirstStep.vue";
import SecondStep from "./Wizard/SecondStep.vue";
import ThirdStep from "./Wizard/ThirdStep.vue";
import { TimeSelect, Select, Option } from 'element-ui'
import swal from "sweetalert2";
import { SimpleWizard, WizardTab } from "src/components";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      wizardModel: {},
      selectedSn: null,
      // isSelectDisabled: false,
      localStorageData: JSON.parse(localStorage.getItem("updateStarted")),
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    SimpleWizard,
    WizardTab,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  mounted() {
    if (this.getAllSn.includes(this.$store.getters.getSnUpdate)) {
      this.selectedSn = this.$store.getters.getSnUpdate;
    } else {
      this.selectedSn = null;
      this.$store.commit("setSnUpdate", null);
    }
    if (this.getAllSn.length == 0) {
      this.$store.commit("setSnUpdate", null);
    }
    if (localStorage.getItem("updateStarted") !== null) {
      this.localStorageData = JSON.parse(localStorage.getItem("updateStarted"));
      this.selectedSn = this.localStorageData.sn;
      this.handleSelectChange();
    }
    // console.log("created", this.getAllSn.length);
    else if (this.$store.getters.getSnUpdate != null) {
      this.selectedSn = this.$store.getters.getSnUpdate;
      this.handleSelectChange();
      // this.isSelectDisabled = true;
      if (this.$store.getters.updateVisibility === "visible")
        this.isSelectDisabled = true;
    }
  },
  computed: {
    ...mapState(["darkMode"]),
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    updatedPrevButtonText() {
      return this.$t("update.previous");
    },
    updatedNextButtonText() {
      return this.$t("update.next");
    },
    updatedFinishButtonText() {
      return this.$t("update.start");
    },
    updatedEndButtonText() {
      return this.$t("update.finish");
    },
    getAllSn() {
      return this.$store.getters.getSnUpdateList;
    },
    ...mapGetters(["updateVisibility"]),
    ...mapGetters(["updateStarted"]),
    isSelectDisabled() {
      if (this.localStorageData) {
        return (
          this.localStorageData.updateStarted === "started" ||
          this.localStorageData.updateStarted === "waiting" ||
          this.localStorageData.updateStarted === "finished" ||
          this.updateVisibility === "visible"
        );
      }
      return false;
    },
  },

  watch: {
    updateVisibility(newValue, oldValue) {
      if (
        newValue === "visible" ||
        this.localStorageData.updateStarted === "started" ||
        this.localStorageData.updateStarted === "waiting"
      ) {
        this.selectedSn = this.$store.getters.getSnUpdate;
        // this.isSelectDisabled = true;
        // console.log("watch from forms", "sn:", this.selectedSn);
      } else if (this.localStorageData.updateStarted === "finished") {
        this.$store.dispatch("updateSn", null);
        // this.isSelectDisabled = true;
      }
    },
  },
  methods: {
    handleSelectChange() {
      // console.log("selectedSn ", this.selectedSn);
      this.$store.dispatch("updateSn", this.selectedSn);
    },
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      swal("Good job!", "You clicked the finish button!", "success");
    },
  },
};
</script>
<style>
.container {
  display: flex;
  justify-content: center;
  align-items: absolute;
}

.darkMode {
  color: #fff !important;
  background-color: #27293d !important;
}

.lightMode {
  color: #000 !important;
  background-color: #fff !important;
}

.select_item {
  /* Reset Select */
  appearance: none;
  outline: 10px red;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;

  background-image: none;
  cursor: pointer;
}

/* Remove IE arrow */
.select_item::-ms-expand {
  display: none;
}

/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  border-radius: 0.25em;
  overflow: hidden;
}

/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #34495e;
  transition: 0.25s all ease;
  pointer-events: none;
}

.select.darkMode::after {
  background-color: #34495e !important;
  /* Dark mode color */
}

.select.lightMode::after {
  background-color: #285891 !important;
  /* Light mode color */
}

/* Transition */
.select:hover::after {
  color: #285891;
}
</style>
